/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Opo = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
          this.openMobileMenu();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
      openMobileMenu: function () {
          $('.nav-bar-open-submenu').click(function (evn) {
              evn.preventDefault();
              $('.submenu-nav-bar-mobile,.main-content-container,.top-menu-button-container').toggleClass('opened');
              if ($('.submenu-nav-bar-mobile').hasClass('opened')) {
                  $('.mobile-submenu').height($(document).height() - $('.top-nav-bar').height());
                  $(window).scrollTop(0);
              }
          });
          $('.mobile-submenu-openable-button').click(function (evn) {
              evn.preventDefault();
              $('.' + $(this).data('for')).toggleClass('opened');
              $(this).toggleClass('opened');
          });
          $('.mobile-submenu-selected').click(function() {
              $('.submenu-nav-bar-opened').toggleClass('hide');
          });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Opo;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
